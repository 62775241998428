<template>
  <div class="modal--medium">
    <button @click="$emit('closeModal')" class="modal--exit">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
          fill="#8E9CA2"
        />
      </svg>
    </button>
    <h2>Скин пользователя</h2>
    <div class="skins_modal" v-if="data.data.length">
      <template v-for="image in data.data">
        <div :key="image.name">
          <img :src="`https://api.pokerham.io${image.path}`" alt="skin" />
        </div>
      </template>
    </div>
    <div v-else>
      <p>Скинов нет.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkinUser',
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
p {
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  color: #435a64;
}
</style>
